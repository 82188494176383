import { ReactComponent as ArrowDown } from "assets/icons/ic_arrow_down.svg";
import Select, { components } from "react-select";
import styled from "styled-components";

export default function Dropdown(props) {
  function DropdownIndicator(props) {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <ArrowDown />
        </components.DropdownIndicator>
      )
    );
  }

  function onMenuOpen() {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "react-select__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ block: "center" });
      }
    }, 15);
  }

  return (
    <CustomSelect
      classNamePrefix="react-select"
      components={{ DropdownIndicator, IndicatorSeparator: null }}
      options={props.options}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: props.dark || props.white ? "white" : "var(--yellow)",
        },
      })}
      defaultValue={props.defaultValue}
      value={props.value}
      onMenuOpen={onMenuOpen}
      onChange={props.onChange}
      placeholder={props.placeholder}
      isSearchable={props.isSearchable}
      width={props.width}
      height={props.height}
      marginRight={props.marginRight}
      indicator={props.indicator}
      maxMenuHeight={192}
      dark={props.dark}
      isDisabled={props.isDisabled}
      noOptionsMessage={props.noOptionsMessage}
      optionHeight={props.optionHeight}
      menuShouldScrollIntoView={false}
      filterOption={props.customFilter}
      {...props}
    />
  );
}

const CustomSelect = styled(Select)`
  .react-select__control {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    min-height: ${(props) => props.height};
    margin-right: ${(props) => props.marginRight};
    border: ${(props) => (props.dark || props.white ? 0 : "1px solid var(--gray300)")};
    background-color: ${(props) => (props.dark ? "var(--gray200)" : null)};
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.white && "var(--gray100)"};
      border: ${(props) => (props.dark || props.white ? 0 : "1px solid var(--gray300)")};
    }

    &:focus {
      background-color: ${(props) => props.white && "var(--gray100)"};
    }
  }

  .react-select__control--is-disabled {
    background-color: var(--gray300);
  }

  .react-select__value-container {
    width: fit-content;
    padding: ${(props) => (props.padding ? props.padding : "0 5px 0 10px")};
  }

  .react-select__indicator {
    display: ${(props) => !props.indicator && "none"};
    padding: 0 5px 0 0;
  }

  .react-select__placeholder {
    color: var(--gray600);
    font-size: 13px;
  }

  .react-select__single-value {
    margin: 0;
    color: var(--gray800);
    font-size: 13px;
    width: fit-content;
  }

  .react-select__menu {
    min-width: fit-content;
    width: ${(props) => props.width};
    margin-top: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0 5px;
    font-size: 13px;
    overflow-y: auto;
    z-index: 1000;
  }

  .react-select__placeholder {
    margin-left: 0;
  }

  .react-select__menu-list {
    min-width: fit-content;
  }

  .react-select__menu-list::-webkit-scrollbar {
    width: 0px;
  }

  .react-select__option {
    min-width: fit-content;
    height: ${(props) => (props.optionHeight ? props.optionHeight : "34px")};
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--gray800);
    font-size: 13px;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
      background-color: #fafbff;
    }
  }

  .react-select__option--is-focused {
    background-color: #fafbff;
  }

  .react-select__option--is-selected {
    background-color: ${(props) => (props.dark ? null : "var(--orange200)")};

    &:hover {
      background-color: ${(props) =>
        props.dark ? null : "var(--orange200)"} !important;
    }
  }
`;

CustomSelect.defaultProps = {
  indicator: true,
};
