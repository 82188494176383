import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from 'assets/icons/ic_logo.svg';
import { ReactComponent as Img1 } from 'assets/icons/landing_1.svg';
import { ReactComponent as Img2 } from 'assets/icons/landing_2.svg';
import { ReactComponent as Img3 } from 'assets/icons/landing_3.svg';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import { sendAmplitudeData } from 'utils/amplitude';
import Layout from '../Layout';

export default function Landing() {
  const navigate = useNavigate();
  const [descriptionNum, setDescriptionNum] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    sendAmplitudeData("랜딩페이지 방문");
  }, [loaded]);

  useEffect(() => {
    const timer = setTimeout(() => setDescriptionNum((state) => {
      if (state === 2) return 0;
      else return state + 1;
    }), 4000);

    return () => clearTimeout(timer);
  }, [descriptionNum]);
  
  return (
    <Layout>
      <div className='flex flex-col px-6 pb-20 w-full h-fit overflow-scroll sm:w-96 sm:mx-auto'>
        <div className='text-2xl font-bold mt-12 mb-4 text-gray1000'>
          나를 힘들게 하는 생각과 행동,
          <br />
          아몬디케어에서 해결해드릴게요!
        </div>
        <div className='text-md mb-10 text-gray1000'>
          심리상담이 필요하신가요?
          <br />
          아몬디케어에서 상담사를 추천해드려요.
        </div>
        <div
          style={{ height: 216 }}
          className='overflow-hidden relative'
        >
          <div
            style={{
              top: descriptionNum === 0 ? 0 : descriptionNum === 1 ? "-100%" : "100%",
              transition: "all 0.5s linear",
              zIndex: descriptionNum === 0 ? 100 : 0,
            }}
            className='bg-pink w-full h-fit rounded-xl pt-6 pl-6 pb-3 pr-3 absolute'
          >
            <div className='text-sm font-semibold mb-2 text-gray1000'>
              진심으로 저를 생각해주시는
              <br />
              상담사를 찾았어요 👏
            </div>
            <div className='text-xs text-gray800'>
              너무 다정하시고 이야기도 잘 들어주셔서
              <br />
              편하게 이야기할 수 있었어요.
            </div>
            <Img1 className='ml-auto'/>
          </div>
          <div
            style={{
              top: descriptionNum === 1 ? 1 : descriptionNum === 2 ? "-100%" : "100%",
              transition: "all 0.5s linear",
              zIndex: descriptionNum === 1 ? 100 : 0,
            }}
            className='bg-lightyellow w-full h-fit rounded-xl pt-6 pl-6 pb-3 pr-3 absolute'
          >
            <div className='text-sm font-semibold mb-2 text-gray1000'>
              노력하면 되겠다는
              <br />
              자신감이 생겼어요 💛
            </div>
            <div className='text-xs text-gray800'>
              평소 문제가 되었던 부분의 원인을
              <br />
              알게되고 나아갈 방향을 찾았어요.
            </div>
            <Img2 className='ml-auto'/>
          </div>
          <div
            style={{
              top: descriptionNum === 2 ? 2 : descriptionNum === 0 ? "-100%" : "100%",
              transition: "all 0.5s linear",
              zIndex: descriptionNum === 2 ? 100 : 0,
            }}
            className='bg-skyblue w-full rounded-xl pt-6 pl-6 pb-3 pr-3 absolute'
          >
            <div className='text-sm font-semibold mb-2 text-gray1000'>
              진심으로 경청하고 공감해주셔서
              <br />
              너무 좋아요 🙌🏻
            </div>
            <div className='text-xs text-gray800'>
              혼자서만 끙끙대던 문제를 털어놓아서
              <br />
              예전보다 행복해질 수 있을 것 같아요.
            </div>
            <Img3 className='ml-auto'/>
          </div>
        </div>
      </div>
      <div
        style={{ bottom: 0, zIndex: 1000 }}
        className='w-full absolute px-6 py-5 bg-white flex'
      >
        <button
          className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000 sm:w-84 sm:mx-auto'
          onClick={() => navigate("/recommend")}
        >
          심리상담사 추천받기
        </button>
      </div>
    </Layout>
  )
}
