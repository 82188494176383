import React, { useEffect, useState } from 'react'
import backgroundImg from 'assets/images/marriage.png';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import { sendAmplitudeData } from 'utils/amplitude';
import Layout from 'screens/Layout';

export default function LandingMarriage() {
  const navigate = useNavigate();
  const [descriptionNum, setDescriptionNum] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    sendAmplitudeData("예비신혼부부 랜딩페이지 방문");
  }, [loaded]);

  useEffect(() => {
    const timer = setTimeout(() => setDescriptionNum((state) => {
      if (state === 2) return 0;
      else return state + 1;
    }), 4000);

    return () => clearTimeout(timer);
  }, [descriptionNum]);
  
  return (
    <Layout>
      <div className='w-full flex flex-col h-fit sm:w-96'>
        <div className='mt-6 mb-10 ml-6 my-auto text-2xl font-bold text-gray1000'>
          이 사람과 결혼해서
          <br />
          행복하게 잘 살 수 있을까?
        </div>
        <div className='relative overflow-visible'>
          <img
            className='w-full'
            src={backgroundImg}
            alt='배경사진'
          />
          <div
            style={{ top: "90%" }}
            className='absolute pl-6 pt-6 bg-white w-full rounded-t-3xl'
          >
            <div className='text-3xl'>👩‍❤️‍👨</div>
            <div className='text-lg font-bold text-gray1000 mt-2'>예비부부 전문 상담사를 추천해요!</div>
            <div className='text-sm text-gray1000 mt-4 mb-8'>
              예비배우자와의 결혼 생각에 설레기도 하지만,
              <br />
              여러 걱정과 불안한 마음이 함께 드시나요?
              <br /><br />
              나와 꼭 맞는 예비부부 전문 상담사, 
              <br />
              아몬디케어에서 찾아드릴게요.
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ bottom: 0, zIndex: 1000 }}
        className='w-full absolute px-6 py-5 bg-white flex flex-col rounded-t-3xl sm:w-96'
      >
        <button
          className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000'
          onClick={() => navigate("/recommend/marriage")}
        >
          예비부부 전문 상담사 추천받기
        </button>
      </div>
    </Layout>
  )
}
