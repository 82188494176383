import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from 'assets/icons/ic_logo.svg';
import { ReactComponent as Img1 } from 'assets/icons/landing_1.svg';
import { ReactComponent as Img2 } from 'assets/icons/landing_2.svg';
import { ReactComponent as Img3 } from 'assets/icons/landing_3.svg';
import backgroundImg from 'assets/images/petloss.png';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import { sendAmplitudeData } from 'utils/amplitude';
import Layout from 'screens/Layout';

export default function LandingTherapy() {
  const navigate = useNavigate();
  const [descriptionNum, setDescriptionNum] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    sendAmplitudeData("펫로스 랜딩페이지 방문");
  }, [loaded]);

  useEffect(() => {
    const timer = setTimeout(() => setDescriptionNum((state) => {
      if (state === 2) return 0;
      else return state + 1;
    }), 4000);

    return () => clearTimeout(timer);
  }, [descriptionNum]);
  
  return (
    <Layout>
      <div className='w-full flex flex-col h-fit overflow-hidden sm:w-96'>
        <div className='mt-6 mb-10 ml-6 my-auto text-2xl font-bold text-gray1000'>
          함께했던 반려동물 생각에
          <br />
          하루가 힘드신가요?
        </div>
        <img
          className='w-full'
          src={backgroundImg}
          alt='배경사진'
        />
      </div>
      <div
        style={{ bottom: 0, zIndex: 1000 }}
        className='w-full absolute px-6 py-5 bg-white flex flex-col rounded-t-3xl sm:w-96'
      >
        <div className='text-3xl'>🐶</div>
        {/* <div className='text-lg font-bold text-gray1000 mt-2'>펫로스 전문 상담사를 추천해요!</div> */}
        <div className='text-sm text-gray1000 mt-4 mb-11'>
          반려동물을 잃은 상실감,
          <br />
          혼자 이겨내기 힘드신가요?
          <br />
          <br />
          펫로스 전문 상담사와 이야기해보세요.
          <br />
          나와 꼭 맞는 상담사를 아몬디케어에서 찾아드릴게요.
        </div>
        <div></div>
        <button
          className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000'
          onClick={() => navigate("/recommend/petloss")}
        >
          펫로스 전문 상담사 추천받기
        </button>
      </div>
    </Layout>
  )
}
