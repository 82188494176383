import { ReactComponent as Logo } from 'assets/icons/ic_logo.svg';
import { useNavigate, useParams } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div
    style={{ minHeight: 48 }}
    className='w-full h-12 border-b border-gray200 flex items-center justify-between px-6'
    >
      <Logo className='cursor-pointer' onClick={() => {
          if (params.type) navigate(`/${params.type}`);
          else navigate("/");
        }}
      />
      <a
        className='bg-gray100 rounded-md p-2 text-sm text-gray1000 font-medium'
        href='mailto:support@amond.care'
        target='_blank'
        rel="noreferrer"
      >
        문의하기
      </a>
    </div>
  )
}
