import Header from "components/Header";

export default function Layout({ children }) {
  return (
    <div className="w-full h-screen flex flex-col overflow-hidden relative items-center">
      <Header />
      {children}
    </div>
  )
}
