import { createGlobalStyle } from 'styled-components';
import Pretendard from 'assets/fonts/Pretendard-1.3.0/public/variable/PretendardVariable.ttf';
import CheckIcon from "./assets/icons/icon-check-mono.svg";

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
    --red: #ff0000;
    --yellow: #FFC533;
    --orange100: #fff4e6;
    --orange200: #ffe8cc;
    --orange300: #ffd8a8;
    --orange400: #ffc078;
    --orange500: #ffa94d;
    --orange600: #ff922b;
    --orange700: #fd7e14;
    --orange800: #f76707;
    --orange900: #e8590c;
    --orange1000: #d9480f;
    --gray100: #f8f9fa;
    --gray200: #f1f3f5;
    --gray300: #e9ecef;
    --gray400: #dee2e6;
    --gray500: #ced4da;
    --gray600: #adb5bd;
    --gray700: #868e96;
    --gray800: #495057;
    --gray900: #343a40;
    --gray1000: #212529;
  }

  html {
    scroll-behavior: auto !important;
  }

  .App {
    height: 100%;
    min-height: 100vh;
  }

  body {
    height: 100%;
    min-height: 100vh;
    overflow: auto;
    margin: 0;
    font-family: Pretendard;
  }

  @font-face {
    font-family: Pretendard;
    src: url(${Pretendard}) format("truetype");
    font-weight: 1 999;
  }

  input {
    border: 1px solid var(--gray300);
    border-radius: 3px;
    padding-left: 10px;
    color: var(--gray800);

    &:focus {
      border: 1px solid var(--yellow);
    }
    
    &::placeholder{
      color: var(--gray600);
    }
  }

  input:focus {
    outline: none;
  }

  input[type='radio'],
  input[type='radio']:checked {
    appearance: none;
  }

  input[type='radio'] {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--gray600);
    background-color: white;
    cursor: pointer;
  }
  
  input[type='radio']:checked {
    padding: 2px;
    border: 1px solid var(--yellow);
    background-color: var(--yellow);
    background-clip: content-box;
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url(${CheckIcon});
  }
`;

export default GlobalStyle;
