import React, { useEffect, useState } from 'react'
import { ReactComponent as Submitted } from 'assets/icons/submitted.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/icon-info-circle-mono.svg';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dropdown from 'components/Dropdown';
import { sendAmplitudeData } from 'utils/amplitude';
import Layout from './Layout';
import styled from 'styled-components';
import { TextareaAutosize } from '@material-ui/core';

export default function Recommendation() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [questionNum, setQuestionNum] = useState(0);
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  const [infoSubmitted, setInfoSubmitted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [etc, setEtc] = useState({});
  const [, forceRender] = useState({});
  const ageList = Array(87).fill().map((_, idx) => {
    return {value: `${13 + idx}세`, label: `${13 + idx}세`}
  });
  const questions = [
    {
      title: "성별을 선택해주세요.",
      type: "select",
      options: ["남성", "여성", "기타"],
    },
    {
      title: "나이를 선택해주세요.",
      type: "dropdown",
      options: ageList,
    },
    // {
    //   title: "이전에 상담 혹은 약물치료 경험이 있으신가요?",
    //   type: "select",
    //   options: ["심리상담 경험이 있어요.", "약물치료 경험이 있어요.", "심리상담이나 정신과 약물치료 경험이 없어요."],
    // },
    {
      title: "도움받고 싶은 문제를 선택해주세요.",
      type: "select",
      options: [
        "우울, 불안 등 정서적 어려움",
        "자신의 성격에 대한 불만",
        "직장 내 스트레스",
        "대인관계 고민 (가족, 연인, 동료 등)",
        "진로 고민 (취업, 이직, 적성 등)",
        "행동 문제(수면의 어려움, 섭식 문제 등)",
        "자살 충동, 자해",
      ],
    },
    {
      title: "도움받고 싶은 문제에 대해 더 구체적으로 얘기해주시면, 좋은 상담사 연결에 큰 도움이 돼요.",
      type: "longinput",
    },
    {
      title: "희망하는 상담 방식이 있나요?",
      type: "select",
      options: [
        "대면상담",
        "화상상담",
        "전화상담",
        "잘 모르겠어요",
      ],
    },
    {
      title: "희망하는 상담 장소를 입력해주세요.",
      type: "input",
      placeholder: "예) 서울시 강남구",
    },
    {
      title: "희망하는 상담자의 나이대가 있나요?",
      type: "select",
      options: ["20~30대", "40대", "50대", "60대 이상", "나이는 무관해요"],
    },
    {
      title: "희망하는 상담자의 성별이 있나요?",
      type: "select",
      options: ["여성 상담자", "남성 상담자", "성별은 무관해요"],
    },
    // {
    //   title: "이런 특성이 있는 상담자를 만나고 싶어요.",
    //   type: "multiselect",
    //   options: [
    //     "자녀 양육 경험이 있는 상담자",
    //     "개신교 상담자",
    //     "천주교 상담자",
    //     "불교 상담자",
    //     "기타 종교가 있는 상담자",
    //     "종교가 없는 상담자",
    //     "성소수자에 대한 차별과 편견이 없는 상담자",
    //     "잘 모르겠어요",
    //   ],
    // },
    {
      title: "이런 스타일의 상담자를 만나고 싶어요.",
      type: "select",
      options: [
        "공감적이고 따뜻한 느낌의 상담자",
        "분석적이고 명확한 느낌의 상담자",
        "차분하고 조용한 성격의 상담자",
        "밝고 외향적인 성격의 상담자",
        "잘 모르겠어요",
      ],
    },
    {
      title: "추천 상담사를 안내해드리기 위한 이메일을 입력해주세요.",
      type: "input",
      placeholder: "이메일 입력",
    },
  ];
  console.log(data);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    sendAmplitudeData("설문 시작");
  }, [loaded]);
  
  function handleQuestionSubmit() {
    // if (
    //   data["희망하는 상담 방식이 있나요?"] === "대면상담 (상담센터에 방문해서 상담을 진행해요)"
    //   && Object.keys(data).length !== questions.length
    // ) return alert('추천을 위한 정보를 모두 입력했는지 한번 더 확인해 주세요.');
    // else if (
    //   data["희망하는 상담 방식이 있나요?"] !== "대면상담 (상담센터에 방문해서 상담을 진행해요)"
    //   && Object.keys(data).length !== (questions.length - 1)
    // ) return alert('추천을 위한 정보를 모두 입력했는지 한번 더 확인해 주세요.');
    // setQuestionSubmitted(true);
    setQuestionNum((state) => state + 1);
    sendAmplitudeData(`${questions[questionNum].title} 입력 완료`);
  }
  
  function handleInfoSubmit() {
    if (!email) return alert('추천 상담사를 안내드릴 이메일을 입력해주세요.');
    if (email !== emailConfirm) return alert('확인 이메일이 일치하지 않습니다.');

    const webhookUrl = 'https://hooks.slack.com/services/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';
    const message = {
      text: `
        \n새로운 상담 신청이 들어왔어요!🎉\n\n${
          questions.map((q) => {
            if (q.type !== "multiselect") return `${q.title} ${data[q.title]}`
            else return `${q.title} ${data[q.title].join(" / ")}`
          }).join("\n")
        }
        \n이메일: ${email}\n
      `,
    };

    return axios.post(webhookUrl, JSON.stringify(message)).then(() => {
      sendAmplitudeData("이메일 작성 완료");
      setInfoSubmitted(true);
    }).catch(() => {
      alert('정상적으로 신청이 접수되지 않았습니다. 다시 시도해주세요.');
    });
  }

  function proceedToNext() {
    if (questionNum === questions.length - 1) {

      const webhookUrl = 'https://hooks.slack.com/services/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';
      const message = {
        text: `
          \n새로운 상담 신청이 들어왔어요!🎉\n\n${
            questions.map((q) => {
              if (q.type !== "multiselect") return `${q.title} ${data[q.title]}`
              else return `${q.title} ${data[q.title]?.join(" / ")}`
            }).join("\n")
          }
        `,
      };
  
      return axios.post(webhookUrl, JSON.stringify(message)).then(() => {
        setQuestionSubmitted(true);
        sendAmplitudeData(`설문 작성 완료`);
      }).catch(() => {
        alert('정상적으로 신청이 접수되지 않았습니다. 다시 시도해주세요.');
      });

    } else if (
      questions[questionNum].title === "희망하는 상담 방식이 있나요?"
      && data[questions[questionNum].title] !== "대면상담"
    ) {
      setQuestionNum((state) => state + 2);
      sendAmplitudeData(`${questions[questionNum].title} 입력 완료`);
    } else {
      setQuestionNum((state) => state + 1);
      sendAmplitudeData(`${questions[questionNum].title} 입력 완료`);
    }
  }

  const handleShare = () => {
    if (navigator.share) {
      sendAmplitudeData("링크 공유");
      navigator.share({
        title: '아몬디',
        text: '나와 맞는 상담사 찾기',
        url: 'https://amond.care/',
      });
    } else {
      sendAmplitudeData("링크 복사");
      navigator.clipboard.writeText('https://amond.care/')
        .then(() => alert("링크가 복사되었습니다."))
        .catch(() => alert("공유를 할 수 없는 환경입니다. 링크를 복사해 친구에게 공유해보세요."));
    }
  }

  return (
    <Layout>
      <>
        {!questionSubmitted && (
          <>
            <div
              style={{ overflow: questions[questionNum].type === "multiselect" ? "scroll" : "visible" }}
              className='w-full h-fit flex flex-col px-6 pb-24 sm:w-96 sm:mx-auto'
            >
              {questionNum === 0 && (
                <>
                  <div className='text-xl font-bold mt-8 mb-3 text-gray1000'>
                    심리상담사 추천받기
                  </div>
                  <div className='text-xs mb-1 text-gray700 flex'>
                    <InfoCircle className='mr-1' />입력하신 모든 정보는 심리상담사 추천을 위해서만 활용돼요.
                  </div>
                  <div className='text-xs mb-1 text-gray700 flex'>
                    <InfoCircle className='mr-1' />심리상담사 추천 이외의 다른 목적으로 절대 활용되지 않아요.
                  </div>
                  <div className='text-xs mb-5 text-gray700 flex'>
                    <InfoCircle className='mr-1' />입력하신 모든 정보는 심리상담사 추천 이후 즉시 파기돼요.
                  </div>
                </>
              )}
              {questionNum !== 0 && (
                <button
                  onClick={() => {
                    sendAmplitudeData(`이전 단계로 돌아감`);
                    if (
                      questions[questionNum].title === "희망하는 상담자의 나이대가 있나요?"
                      && data["희망하는 상담 방식이 있나요?"] !== "대면상담"
                    ) {
                      setQuestionNum((state) => state - 2);
                    } else {
                      setQuestionNum((state) => state - 1);
                    }
                  }}
                  style={{ minHeight: 28 }}
                  className='w-7 h-7 mt-4 rounded-md bg-gray100 flex items-center justify-center'
                >
                  <Back />
                </button>
              )}
              <div className='text-xl mt-6 mb-5 text-gray1000 font-bold'>
                {questions[questionNum].title}
              </div>
              {questions[questionNum].type === "select" && (
                questions[questionNum].options.map((o) => (
                  <button
                    style={{ minHeight: 48, backgroundColor: data[questions[questionNum].title] === o && "var(--yellow)" }}
                    className='bg-gray100 rounded-md h-12 mb-2.5 text-gray1000 text-base font-bold'
                    onClick={() => {
                      const newData = data;
                      data[questions[questionNum].title] = o;
                      setData(newData);
                      proceedToNext();
                    }}
                  >
                    {o}
                  </button>
                ))
              )}
              {questions[questionNum].type === "multiselect" && (
                <>
                  {questions[questionNum].options.map((o, i) => (
                    <button
                      type="button"
                      className="form-check flex flex-row items-center mb-4"
                    >
                      <input
                        className="form-check-input appearance-none h-5 w-5 mr-2
                          border border-solid border-gray600 rounded-sm
                          checked:border-yellow focus:outline-none
                          bg-white checked:bg-yellow
                          transition duration-200 cursor-pointer"
                        type="checkbox"
                        checked={data[questions[questionNum].title]?.includes(o)}
                        value={o}
                        id={`multiselect_${questionNum}_${i}`}
                        onChange={() => {
                          const newData = data;
                          if (!newData[questions[questionNum].title]) {
                            newData[questions[questionNum].title] = [o];
                          } else if (
                            newData[questions[questionNum].title].some((e) => e === o)
                          ) {
                            newData[questions[questionNum].title] = newData[
                              questions[questionNum].title
                            ].filter((e) => e !== o);
                          } else {
                            newData[questions[questionNum].title].push(o);
                          }
                          setData(newData);
                          forceRender({});
                        }}
                      />
                      <label
                        className="form-check-label inline-block text-base text-gray800"
                        htmlFor={`multiselect_${questionNum}_${i}`}
                      >
                        {o}
                      </label>
                    </button>
                  ))}
                  <button
                    type="button"
                    className="form-check flex flex-row items-center mb-4"
                  >
                    <input
                      className="form-check-input appearance-none h-5 w-5 mr-2
                        border border-solid border-gray600 rounded-xs
                        checked:border-yellow focus:outline-none
                        bg-white checked:bg-yellow
                        transition duration-200 cursor-pointer"
                      type="checkbox"
                      id={`multiselect_${questionNum}_etc`}
                      checked={data[questions[questionNum].title]?.includes(
                        `etc${etc[questions[questionNum].title]}`
                      )}
                      onChange={() => {
                        const newData = data;
                        if (!newData[questions[questionNum].title]) {
                          newData[questions[questionNum].title] = [`etc${etc[questions[questionNum].title]}`];
                        } else if (
                          newData[questions[questionNum].title].some((e) => e === `etc${etc[questions[questionNum].title]}`)
                        ) {
                          newData[questions[questionNum].title] = newData[
                            questions[questionNum].title
                          ].filter((e) => e !== `etc${etc[questions[questionNum].title]}`);
                        } else {
                          newData[questions[questionNum].title].push(`etc${etc[questions[questionNum].title]}`);
                        }
                        setData(newData);
                        forceRender({});
                      }}
                    />
                    <input
                      className='w-60 h-6 text-base rounded-none border-0 border-b border-gray600 pl-0'
                      style={{ minHeight: 24 }}
                      value={etc[questions[questionNum].title]}
                      onChange={(e) => {
                        const newEtc = etc;
                        newEtc[questions[questionNum].title] = e.target.value;
                        setEtc(newEtc);
                        forceRender({});

                        if (!data[questions[questionNum].title]) return;

                        const etcIndex = data[questions[questionNum].title].findIndex((el) => el.includes("etc"));
                        console.log(etcIndex);
                        if (etcIndex !== -1) {
                          const newData = data;
                          newData[questions[questionNum].title][etcIndex] = `etc${e.target.value}`;
                          setData(newData);
                        }
                      }}
                      placeholder="기타 (직접 입력)"
                    />
                  </button>
                </>
              )}
              {questions[questionNum].type === "dropdown" && (
                <Dropdown
                  options={ageList}
                  onChange={(value) => {
                    const newData = data;
                    data[questions[questionNum].title] = value.value;
                    setData(newData);
                    proceedToNext();
                  }}
                  placeholder="나이를 선택해주세요."
                  value={ageList.find((e) => e.value === data[questions[questionNum].title])}
                  isSearchable={false}
                  width="100%"
                  height="48px"
                />
              )}
              {questions[questionNum].type === "input" && (
                <input
                  className='w-full h-12 text-sm'
                  style={{ minHeight: 48 }}
                  value={data[questions[questionNum].title]}
                  onChange={(e) => {
                    const newData = data;
                    data[questions[questionNum].title] = e.target.value;
                    setData(newData);
                    forceRender({});
                  }}
                  placeholder={questions[questionNum].placeholder}
                />
              )}
              {questions[questionNum].type === "longinput" && (
                <LongInput
                  value={data[questions[questionNum].title]}
                  onChange={(e) => {
                    const newData = data;
                    data[questions[questionNum].title] = e.target.value;
                    setData(newData);
                    forceRender({});
                  }}
                  placeholder={questions[questionNum].placeholder}
                />
              )}
            </div>
            {(questions[questionNum].type !== "select" && questions[questionNum].type !== "dropdown") && (
              <div
                style={{ bottom: 0 }}
                className='w-full absolute px-6 py-5 bg-white flex'
              >
                <button
                  className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000 sm:w-84 sm:mx-auto'
                  onClick={proceedToNext}
                >
                  다음
                </button>
              </div>
            )}
          </>
        )}
        {questionSubmitted && (
          <div className='w-full h-full flex flex-col items-center pt-12 px-6'>
            <Submitted />
            <div className='text-xl font-bold mt-8 mb-4 text-gray1000'>
              심리상담사 검색을 시작합니다.
            </div>
            <div className='text-sm text-gray800 text-center'>
              가장 적합한 심리상담사를 찾은 후에
              <br />
              이메일로 안내드릴게요 :D
            </div>
            <button
              className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000 mt-20 sm:w-84 sm:mx-auto'
              onClick={handleShare}
            >
              친구에게 공유하기
            </button>
            <button
              className='w-full bg-gray100 rounded py-3 text-base font-bold text-gray1000 mt-2 sm:w-84 sm:mx-auto'
              onClick={() => navigate("/")}
            >
              돌아가기
            </button>
          </div>
        )}
      </>
    </Layout>
  )
}

const LongInput = styled(TextareaAutosize)`
  width: 100%;
  min-height: 64px;
  border: 1px solid var(--gray300);
  border-radius: 3px;
  padding: 10px;
  resize: none;
  font-size: 14px;
  color: var(--gray800);
  word-break: break-all;

  &:focus {
    outline: 1px solid var(--yellow);
  }

  &::placeholder {
    color: var(--gray600);
    font-size: 14px;
  }
`;