import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendAmplitudeData } from 'utils/amplitude';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { ReactComponent as Applied } from 'assets/icons/therapyApplied.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron_left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron_right.svg';
import Layout from './Layout';
import axios from 'axios';
import dayjs from 'dayjs';
import { loadTossPayments } from '@tosspayments/payment-sdk';

export default function TherapistInfo() {
  const [info, setInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [step, setStep] = useState(0);
  const [contact, setContact] = useState("");
  const params = useParams();
  const [curDate, setCurDate] = useState(dayjs(new Date()).startOf("date"));
  const [time, setTime] = useState("");
  const [type, setType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("카드");
  const [, forceRender] = useState({});

  const dayNames = ["일", "월", "화", "수", "목", "금", "토"]

  function getMonthMatrix(date) {
    const year = date.year();
    const month = date.month();
  
    const firstDayOfMonth = date.startOf("month").day();
    const lastDateOfMonth = date.endOf("month").date();
  
    const numOfWeeks = Math.ceil((lastDateOfMonth + firstDayOfMonth) / 7);
    let curDate = 0 - firstDayOfMonth;
  
    return {
      numOfWeeks,
      matrix: new Array(numOfWeeks).fill([]).map(() =>
        new Array(7).fill(0).map(() => {
          curDate++;
          return dayjs(new Date(year, month, curDate));
        })
      ),
    };
  }

  const therapistData = {
    shjang: {
      name: "장성훈",
      photo: "shjang.png",
      position: "-6px 0",
      description: "여기까지 오느라 고생 많으셨어요. 이제는 홀로 고민하기보다 같이 나누었으면 합니다.\n\n당신의 여정에 함께 하는 상담자가 되겠습니다.",
      currentJobs: [
        "삼육대학교 학생상담센터 전임상담원",
      ],
      previousJobs: [
        "구로구청소년상담복지센터 실습상담원",
        "가톨릭대학교 대학원상담실 상담원",
        "서강대학교 학생생활연구소 인턴상담원",
      ],
      educations: [
        "가톨릭대학교 심리학 석사 (상담심리전공)",
      ],
      certificates: [
        "상담심리사 2급 (한국상담심리학회)",
      ],
      fields: [
        "불안", "우울", "대인관계", "자존감", "적용", "가족불화"
      ],
      prices: [
        "전화상담 40,000원",
      ],
      availableDate: {
        "22/08/10" : ["11:00 ~ 12:00"],
        "22/08/12" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/12" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/15" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/19" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/22" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/26" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
        "22/09/29" : ["11:00 ~ 12:00", "13:00 ~ 14:00"],
      },
    },
    shkim: {
      name: "김서하",
      photo: "shkim.jpeg",
      position: "0 0",
      description: "삶에서 경험하는 여러 문제들 중에서\n혼자 힘만으론 감당하기 어려운 문제들이 있어요.\n심리상담에서는 불안/우울/진로고민/대인관계 문제 등\n내가 겪고 있는 문제를 전문가의 도움을 통해\n지금까지와는 다른 방향으로 바라보고,\n내가 원하는 삶을 위한 선택을 할 수 있도록 새로운 시도를 해보게 됩니다.\n\n저와의 만남을 통해 지금 경험하고 있는 어려움으로부터 보다 자유로워지고 내 색깔대로 살아갈 수 있는 방법을 찾을 수 있었으면 좋겠어요.\n\n평상시 심리상담을 받아보고 싶었으나 주저하셨던 분들,\n심리상담이 무엇인지 궁금하신 분들,\n우울, 불안, 스트레스, 대인관계, 가족관계 등과 관련된\n심리적 어려움을 혼자 힘으로 다루기 어렵다고 느끼는 분들 모두 심리상담을 통해 도움을 받을 수 있는 분들이에요.\n​\n본 상담은 안전하고 효과적인 상담을 위하여\n한국상담심리학회 상담심리전문가(상담심리사1급)에게\n상담에 대한 자문과 지도를 받으며 진행됩니다 :)",
      currentJobs: [
        "청소년상담복지센터 상담원",
      ],
      previousJobs: [
        "서울대학교 대학생활문화원 실습상담원",
        "이화여자대학교 학생상담센터 인턴쉽 수료",
        "EAP(*직장인 대상 심리상담) 전문기업 다인 선임연구원",
      ],
      educations: [
        "서울대학교 심리학과 학사 졸업",
        "서울대학교 일반대학원 임상, 상담심리학 석사 졸업",
      ],
      certificates: [
        "임상심리사 2급(국가기술자격)",
        "청소년상담사 2급(국가기술자격)",
        "상담심리사 2급(한국상담심리학회)",
      ],
      fields: [
        "불안", "우울", "대인관계", "자존감", "적용", "가족불화"
      ],
      prices: [
        "전화상담 1시간 50,000원",
        "화상상담 1시간 50,000원",
        "대면상담 1시간 50,000원",
      ],
      availableDate: {},
    }
  };

  useEffect(() => {
    setInfo(therapistData[params.id]);
    setLoaded(true);
  }, [])

  useEffect(() => {
    if (!loaded) return;
    sendAmplitudeData(`상담사 프로필 확인`);
  }, [loaded]);

  return (
    <Layout>
      {step === 0 && (
        <>
          <div className='w-full h-fit overflow-scroll relative flex flex-col p-6 pb-52 sm:w-96 sm:mx-auto'>
            <div className='flex flex-row items-center'>
              <div className='h-10 w-10 overflow-hidden rounded-full'>
                <img
                  className='relative'
                  style={info.position && { top: info.position.split(" ")[0], left: info.position.split(" ")[1] }}
                  src={info.photo && require(`../assets/images/${info.photo}`)}
                  alt='상담사 프로필'
                />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='text-sm text-gray1000 font-medium mb-1'>
                  {info.name} 상담사
                </div>
                <div className='text-xs text-gray700'>
                  {info.currentJobs && info.currentJobs[0]}
                </div>
              </div>
            </div>
            <div className='mt-6 text-xl font-bold text-gray1000'>
              안녕하세요,
              <br />
              {info.name} 상담사입니다.
            </div>
            <div className='mt-4 text-sm text-gray800 whitespace-pre-wrap'>
              {info.description}
            </div>
            <div className='mt-16 pt-1.5 pb-6 flex flex-row border-t border-gray200'>
              <div className='w-20 text-sm font-medium text-gray700'>비용</div>
              <div className='text-sm text-gray1000'>
                {info.prices && info.prices.map((e) =>
                  <>
                    {e}
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className='pt-1.5 pb-6 flex flex-row border-t border-gray200'>
              <div
                style={{ minWidth: 80 }}
                className='w-20 text-sm font-medium text-gray700'
              >
                자격
              </div>
              <div className='text-sm text-gray1000'>
                {info.certificates && info.certificates.map((e) =>
                  <>
                    {e}
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className='pt-1.5 pb-5 flex flex-row border-t border-gray200'>
              <div
                style={{ minWidth: 80 }}
                className='w-20 text-sm font-medium text-gray700'
              >
                전문분야
              </div>
              <div className='flex flex-wrap'>
                {info.fields && info.fields.map((e) =>
                  <div className='bg-orange200 py-1 px-1.5 mr-1 mb-1 rounded-3xl text-xs text-gray1000'>
                    {e}
                  </div>
                )}
              </div>
            </div>
            <div className='pt-1.5 pb-6 flex flex-row border-t border-gray200'>
              <div className='w-20 text-sm font-medium text-gray700'>이력</div>
              <div className='text-sm text-gray1000'>
                {info.currentJobs && info.currentJobs.map((e) =>
                  <>
                    현) {e}
                    <br />
                  </>
                )}
                {info.previousJobs && info.previousJobs.map((e) =>
                  <>
                    전) {e}
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className='pt-1.5 pb-6 flex flex-row border-t border-gray200'>
              <div className='w-20 text-sm font-medium text-gray700'>학력</div>
              <div className='text-sm text-gray1000'>
                {info.educations && info.educations.map((e) =>
                  <>
                    {e}
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ bottom: 0 }}
            className='w-full absolute bg-white flex flex-col'
          >
            <div className='w-full px-6 py-5 flex'>
              <button
                className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000 sm:w-84 sm:mx-auto'
                onClick={() => {
                  sendAmplitudeData("상담예약 시작");
                  setStep(1);
                }}
              >
                상담 예약하기
              </button>
            </div>
            <div className='w-full bg-gray400 p-5 text-gray800 font-medium text-sm text-center sm:text-left'>
              <div>(주) 아몬디 | 대표 : 강신영</div>
              <div>사업자등록번호 : 463-88-02658</div>
              <div>경기도 성남시 분당구 양현로 220</div>
              <div>전화번호 : +82-10-6263-3082</div>
            </div>
          </div>
        </>
      )}
      {step === 1 && (
        <div className='px-6 pt-4 pb-5 w-full h-full flex flex-col sm:w-96 sm:mx-auto overflow-scroll'>
          <button
            onClick={() => {
              sendAmplitudeData(`상담사 프로필로 돌아감`);
              setStep(0);
            }}
            style={{ minHeight: 28 }}
            className='w-7 h-7 rounded-md bg-gray100 flex items-center justify-center'
          >
            <Back />
          </button>
          <div className='my-5 text-xl font-bold text-gray1000'>
            상담을 원하시는
            <br />
            날짜를 선택해주세요
          </div>
          <div className='w-100 flex items-center justify-center mt-2 mb-5'>
            <button
              className='h-6 w-6 bg-gray100 hover:bg-gray200 rounded flex items-center justify-center'
              onClick={() => setCurDate(state => state.subtract(1, "month"))}
            >
              <ChevronLeft />
            </button>
            <div className='mx-3 text-base font-bold text-gray1000'>{curDate.year()}년 {curDate.month()+1}월</div>
            <button
              className='h-6 w-6 bg-gray100 hover:bg-gray200 rounded flex items-center justify-center'
              onClick={() => setCurDate(state => state.add(1, "month"))}
            >
              <ChevronRight />
            </button>
          </div>
          <div
            style={{ minHeight: 40 }}
            className='h-10 border-b border-gray200 flex items-center justify-between'
          >
            {dayNames.map((day) => (
              <div
                className='w-9 text-sm text-gray800 text-center'
                style={{ minWidth: 36 }}
                key={day}
              >
                {day}
              </div>
            ))}
          </div>
          {getMonthMatrix(curDate).matrix.map((week, i) => (
            <div className='h-9 flex items-center justify-between mt-2.5' key={i}>
              {week.map((date, j) => (
                <button
                  className='h-9 w-9 text-sm text-center rounded-full'
                  style={{
                    minWidth: 36,
                    color: (!date.diff(curDate)) ? "white" : (Object.keys(info.availableDate).includes(date.format("YY/MM/DD"))) ? "var(--gray800)" : "var(--gray600)",
                    textDecoration: (!Object.keys(info.availableDate).includes(date.format("YY/MM/DD"))) && "line-through",
                    backgroundColor: (!date.diff(curDate)) && "var(--gray1000)",
                  }}
                  // id={!dayjs(date).diff(curDate, "d") ? "today" : "notToday"}
                  onClick={() => {
                    console.log("date!!!", date);
                    setCurDate(date)
                  }}
                  // onDoubleClick={() => handleAddCalendar()}
                  key={j}
                >
                  {date.date()}
                </button>
              ))}
            </div>
          ))}
          <div className='flex flex-col mt-3'>
            {info.availableDate[curDate.format("YY/MM/DD")]?.map((time) => 
              <button
                className='w-full h-10 bg-gray100 flex items-center justify-center rounded text-gray1000 text-sm font-medium mt-2'
                onClick={() => {
                  setStep(2);
                  setTime(time);
                }}
              >
                {time}
              </button>
            )}
          </div>
          {/* <button
            onClick={() => {
              const webhookUrl = 'https://hooks.slack.com/services/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';
              const message = {
                text: `
                  \n실제 상담 요청이 들어왔어요!🎉\n연락처: ${contact}\n상담사: ${info.name} 상담사\n
                `,
              };

              return axios.post(webhookUrl, JSON.stringify(message)).then(() => {
                sendAmplitudeData("상담 요청 완료");
                setStep(2);
              }).catch(() => {
                alert('정상적으로 요청이 접수되지 않았습니다. 다시 시도해주세요.');
              });
        
            }}
            disabled={contact.length < 11}
            style= {{
              backgroundColor: contact.length > 10 ? "var(--yellow)" : "var(--gray200)",
              color: contact.length > 10 ? "var(--gray1000)" : "var(--gray600)",
            }}
            className="w-full p-3 text-base font-bold rounded text-center mt-9"
          >
            완료
          </button> */}
        </div>
      )}
      {step === 2 && (
        <div className='px-6 pt-4 pb-5 w-full h-full flex flex-col sm:w-96 sm:mx-auto overflow-scroll'>
          <button
            onClick={() => {
              sendAmplitudeData(`시간 선택으로 돌아감`);
              setStep(1);
            }}
            style={{ minHeight: 28 }}
            className='w-7 h-7 rounded-md bg-gray100 flex items-center justify-center'
          >
            <Back />
          </button>
          <div className='text-sm text-gray1000 mt-5'>
            {curDate.format("YYYY년 M월 DD일")} {time}
          </div>
          <div className='mt-3 mb-16 text-xl font-bold text-gray1000'>
            상담 방식을 선택해주세요
          </div>
          {info.prices.map((p) =>(
            <button
              className='w-full py-3 px-4 bg-gray100 rounded mt-4 flex flex-col'
              onClick={() => {
                setType(p);
                setStep(3);
              }}
            >
              <div className='w-full text-sm text-gray1000 font-medium mb-2 flex items-center justify-between'>
                <div>{p.split(" ")[0]}</div>
                <div className='font-bold'>{p.split(" ")[1]}</div>
              </div>
              <div className='w-full text-xs text-gray700 flex items-center justify-between'>
                <div>{p.split(" ")[0]==="전화상담" && "전화로 상담을 진행해요"}</div>
                <div>{p.split(" ")[0]==="대면상담" && "상담사와 직접 만나서 진행해요"}</div>
              </div>
            </button>
          ))}
        </div>
      )}
      {step === 3 && (
        <>
          <div className='w-full h-fit overflow-scroll relative flex flex-col pt-4 pb-32 sm:w-96 sm:mx-auto'>
            <button
              onClick={() => {
                sendAmplitudeData(`방식 선택으로 돌아감`);
                setStep(1);
              }}
              style={{ minHeight: 28 }}
              className='w-7 h-7 rounded-md bg-gray100 flex items-center justify-center ml-6'
            >
              <Back />
            </button>
            <div className='flex flex-row items-center py-6 pl-6 border-b-8'>
              <div className='h-10 w-10 overflow-hidden rounded-full'>
                <img
                  className='relative'
                  style={info.position && { top: info.position.split(" ")[0], left: info.position.split(" ")[1] }}
                  src={info.photo && require(`../assets/images/${info.photo}`)}
                  alt='상담사 프로필'
                />
              </div>
              <div className='flex flex-col ml-2'>
                <div className='text-sm text-gray1000 font-medium mb-1'>
                  {info.name} 상담사
                </div>
                <div className='text-xs text-gray700'>
                  {info.currentJobs && info.currentJobs[0]}
                </div>
              </div>
            </div>
            <div className='flex flex-col py-6 pl-6 border-b-8'>
              <div className='text-base font-bold text-gray1000'>예약 정보</div>
              <div className='flex items-center text-sm font-medium text-gray800 mt-6'>
                <div className='mr-4'>날짜</div>
                {curDate.format("YYYY년 M월 DD일")}
              </div>
              <div className='flex items-center text-sm font-medium text-gray800 mt-6'>
                <div className='mr-4'>시간</div>
                {time}
              </div>
            </div>
            <div className='flex flex-col py-6 pl-6 border-b-8'>
              <div className='text-base font-bold text-gray1000'>결제 수단</div>
              <div className='flex items-center text-sm font-medium text-gray800 mt-6'>
                <button
                  type="button"
                  className="mb-3 text-sm text-gray800 flex items-center cursor-pointer"
                  onClick={() => {
                    setPaymentMethod("카드")
                    forceRender({});
                  }}
                >
                  <input
                    type="radio"
                    className="mr-2"
                    checked={paymentMethod === "카드"}
                  />
                  신용/체크카드
                </button>
              </div>
            </div>
            <div className='flex flex-col py-6 px-6'>
              <div className='text-base font-bold text-gray1000 flex justify-between'>
                <div>총 결제금액</div>
                {type.split(" ")[1]}
              </div>
              <div className='flex items-center text-sm font-medium text-gray800 mt-6 flex justify-between'>
                <div>{type.split(" ")[0]}</div>
                {type.split(" ")[1]}
              </div>
            </div>
          </div>
          <div
            style={{ bottom: 0 }}
            className='w-full absolute px-6 py-5 bg-white flex flex-col border-t'
          >
            <div className='mb-5 text-xs text-gray800 text-center'>
              위 내용을 모두 확인하였으며, 결제에 동의합니다.
              <br />
              <br />
              *상담 진행 전에는 언제든 100% 환불이 가능합니다.
            </div>
            <button
              className='w-full bg-yellow rounded py-3 text-base font-bold text-gray1000 sm:w-84 sm:mx-auto'
              onClick={() => {
                sendAmplitudeData("결제버튼 클릭");
                loadTossPayments("test_ck_YZ1aOwX7K8mvODpm0wm3yQxzvNPG").then(tossPayments => 
                  tossPayments.requestPayment(paymentMethod, {
                    amount: 15000,
                    orderId: 'oG9IEfogDQDz2PfXfGLx6',
                    orderName: '토스 티셔츠 외 2건',
                    customerName: '박토스',
                    successUrl: `http://amond.care/therapist-info/${params.id}`,
                    failUrl: `http://amond.care/therapist-info/${params.id}`,
                }));
              }}
            >
              결제하기
            </button>
          </div>
        </>
      )}
      {step === 4 && (
        <div className='w-full h-full flex flex-col items-center pt-12 px-6'>
          <Applied />
          <div className='text-xl font-bold mt-8 mb-4 text-gray1000 text-center'>
            상담사님에게
            <br />
            연락처를 전달해드릴게요.
          </div>
          <div className='text-sm text-gray800 text-center'>
            상담사님이 곧 연락을 드릴 예정이에요.
            <br />
            만약 일주일 내 연락이 오지 않는다면
            <br />
            아몬디케어에 문의해주세요.
          </div>
        </div>
      )}
    </Layout>
  )
}
