import Header from 'components/Header';
import GlobalStyle from 'GlobalStyle';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingMarriage from 'screens/Landing/LandingMarriage';
import LandingPetLoss from 'screens/Landing/LandingPetLoss';
import LandingTherapy from 'screens/Landing/LandingTherapy';
import PaymentSuccess from 'screens/PaymentSuccess';
import Payment from 'screens/PaymentSuccess';
import Recommendation from 'screens/Recommendation';
import TherapistInfo from 'screens/TherapistInfo';
import Landing from 'screens/Landing/Landing';
import RecommendationReduced from 'screens/RecommendationReduced';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyle />
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/marriage" element={<LandingMarriage />} />
          <Route exact path="/petloss" element={<LandingPetLoss />} />
          <Route exact path="/therapy" element={<LandingTherapy />} />
          <Route exact path="/recommend" element={<Recommendation />} />
          <Route exact path="/recommend/:type" element={<RecommendationReduced />} />
          <Route exact path="/therapist-info/:id" element={<TherapistInfo />} />
          <Route exact path="/therapist-info/payment-success/:id/:date/:time/:type" element={<PaymentSuccess />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;