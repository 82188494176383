import { loadTossPayments } from '@tosspayments/payment-sdk'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

export default function PaymentSuccess() {
  const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
  const params = useParams();

  return (
    <div>
      {params.id}
      {params.date}
      {params.time}
      {params.type}
    </div>
  )
}
